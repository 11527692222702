import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonImg, IonInput, IonPage, IonProgressBar, IonRow, IonText } from '@ionic/react';
import { Address, AppConfig, ConfirmDirectDebitAsync, GetConfig, GetOnboardingAsync, Onboarding, SetTestOnboardingBankAccountAsync, StepNames, StepStatus } from '../../../lib';
import './DirectDebit.css';
import { AccountContext } from '../../controls/nav/AccountProvider';
import { ButtonRef, SubmitButton, SubmitButtonSquare } from '../../controls/shared/Buttons';
import { GridWithRowAndMiddleColumn, RowWithDynamicGutter } from '../../controls/shared/Grid';

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function DirectDebitStep(props: Props) {
    const [onboarding, setOnboarding] = useState<Onboarding>(props.onboarding);
    const [address, setAddress] = useState<Address>(onboarding.businessDetails.address!); // convenience state for editing in-form
    const [errorMessage, setErrorMessage] = useState<string>();
    const accountContext = useContext(AccountContext);
    const [isChecked, setIsChecked] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const [config, setConfig] = useState<AppConfig>();
    const buttonRef = useRef<ButtonRef>(null);

    useEffect(() => { GetConfig().then(c => setConfig(c)); }, []);

    async function doDirectDebit() {
        var response = await ConfirmDirectDebitAsync(address);

        if (response?.steps?.get(StepNames.DirectDebitMandate)?.status === StepStatus.Complete) {
            props.onNext();
            return;
        }

        setErrorMessage("Failed to create the direct debit.  Please check the details and try again if they are wrong, otherwise please contact us.");
        setIsChecked(false);
        buttonRef.current?.stopSpinning(true);
    };

    function start() {
        setErrorMessage(undefined);
        setIsStarted(true);
    }

    async function onClickTestBankAccountButton(e: any) {
        await SetTestOnboardingBankAccountAsync();
        let response = await GetOnboardingAsync();
        if (response) {
            setOnboarding(response);
        }
    }
    function goBack() {
        props.onNext()
    }
    return <>
        <IonPage>
            <IonContent>
                <IonGrid>
                    <RowWithDynamicGutter>
                        <IonImg className='dDMandateScreenImg' src='./assets/img/logo.png' />
                        <h5 className='sectionTitle'>
                            <b>Direct Debit Setup</b>
                        </h5>
                        <p className='onboardingSubText'>
                            Please confirm the details below are the ones you provided and are correct.
                        </p>
                        <IonText color={"primary"}><h6 className='directDebitFormText'>Email:</h6></IonText>
                        <IonInput class='directDebitInputs' disabled={true} value={accountContext?.account?.emailAddress}></IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonText color={"primary"}><h6 className='directDebitFormText'>Address line 1:</h6></IonText>
                        <IonInput
                            color={"black"}
                            class='directDebitAddressInput editableField'
                            value={address?.addressLine1}
                            onIonChange={e => setAddress({ ...address, addressLine1: e.detail.value! })}></IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonText color={"primary"}><h6 className='directDebitFormText'>Address line 2:</h6></IonText>
                        <IonInput

                            class='directDebitAddressInput editableField'
                            value={address?.addressLine2}
                            onIonChange={e => setAddress({ ...address, addressLine2: e.detail.value! })}></IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonText color={"primary"}><h6 className='directDebitFormText'>City:</h6></IonText>
                        <IonInput
                            class='directDebitAddressInput editableField'
                            value={address?.city}
                            onIonChange={e => setAddress({ ...address, city: e.detail.value! })}></IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonText color={"primary"}><h6 className='directDebitFormText'>Country:</h6></IonText>
                        <IonInput class='directDebitAddressInput editableField'
                            value={address?.country}
                            onIonChange={e => setAddress({ ...address, country: e.detail.value! })}
                        ></IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonText color={"primary"}><h6 className='directDebitFormText'>Postcode:</h6></IonText>
                        <IonInput
                            class='directDebitAddressInput editableField'
                            value={address?.postalCode}
                            onIonChange={e => setAddress({ ...address, postalCode: e.detail.value! })}></IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonText color={"primary"}><h6 className='directDebitFormText'>Account holder name:</h6> </IonText>
                        <IonInput class='directDebitInputs' disabled={true} value={onboarding?.bankAccount?.accountHolderName}></IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonRow>
                            <IonCol size='6'>
                                <IonText color={"primary"}><h6 className='directDebitFormText'>Account number:</h6></IonText>
                                <IonInput class='directDebitInputs' disabled={true} value={onboarding?.bankAccount?.accountNumber}></IonInput>
                            </IonCol>
                            <IonCol size='6'>
                                <IonText color={"primary"}><h6 className='directDebitFormText'>Sort code:</h6></IonText>
                                <IonInput class='directDebitInputs' disabled={true} value={onboarding?.bankAccount?.sortCode}></IonInput>
                            </IonCol>
                        </IonRow>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonRow className='confirmationRow'>
                            <IonCol sizeXs='10' sizeSm='10' sizeMd='10' sizeLg='10' sizeXl='10'>
                                <IonText color={"primary"}>
                                    <h6 className='directDebitFormText' style={{ fontWeight: 500 }}>
                                        I confirm that I am the account holder and am authorised to set up Direct Debit payments on this account.
                                    </h6>
                                </IonText>
                            </IonCol>
                            <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='2' sizeXl='2'>
                                <IonCheckbox checked={isChecked} onIonChange={e => setIsChecked(e.detail.checked)} className='dDCheckbox'></IonCheckbox>
                            </IonCol>
                        </IonRow>
                    </RowWithDynamicGutter>
                    {errorMessage &&
                        <RowWithDynamicGutter >
                            <IonCol sizeXs='1' sizeSm='1' sizeMd='2' sizeLg='2' sizeXl='2' />
                            <IonCol sizeXs='10' sizeSm='10' sizeMd='8' sizeLg='8' sizeXl='8'>
                                <p className='error-message'> {errorMessage}</p>
                            </IonCol>
                            <IonCol sizeXs='1' sizeSm='1' sizeMd='2' sizeLg='2' sizeXl='2' />
                        </RowWithDynamicGutter>}
                    <RowWithDynamicGutter>
                        {(config?.isDevelopment === true || config?.isStaging === true) && (
                            <IonButton onClick={onClickTestBankAccountButton}>Test Account info</IonButton>
                        )}
                        <div className='bottomButtonContainer'>
                            <div className='bottomButtonRow'>
                                <IonButton className='squareBackButton' onClick={goBack}>Go Back &#187;</IonButton>
                                <SubmitButton ref={buttonRef} disabled={!isChecked} onClick={doDirectDebit} text="Complete &#187;" />
                            </div></div>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonRow>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4' >
                            </IonCol>
                            <IonCol sizeXs='4' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'>
                                <img className="dDLogo" src='../assets/img/ddlogo.png' width="280px" height="30px" alt='Direct Debit Logo' /></IonCol>
                        </IonRow>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonText className='goCardText'><span>
                            <i>TrustistTransfer <br></br>
                                info@trustisttransfer.com  |  +44 1904 217108<br></br>
                                Payments to us will be shown as TRUSTISTLTD on your bank statements<br></br>
                                By confirming you are agreeing to our Website <a className='goCardText' href='https://trustisttransfer.com/terms-and-conditions/'>Terms and Conditions.</a><br></br>
                                Payments by GoCardless. Read the GoCardless privacy notice <a className='goCardText' href='https://gocardless.com/privacy/'>here</a>. <br></br>
                                We use analytics cookies.<br></br>
                                Your payments are protected by the <a className='goCardText' href='https://gocardless.com/direct-debit/guarantee/'> Direct Debit Guarantee</a><br></br>
                                You may cancel this Direct Debit at any time by contacting Trustist or your bank.
                            </i></span>
                        </IonText>
                    </RowWithDynamicGutter>
                </IonGrid>
            </IonContent>
        </IonPage>
    </>;

    // return <>{isStarted === true
    //     ? onboarding?.steps?.get(StepNames.DirectDebitMandate)?.status === StepStatus.Complete ? props.onNext() : needDirectDebit()
    //     : <>
    //         <IonGrid>
    //             <RowWithDynamicGutter>
    //                 <IonImg className="smallLogo" src="./assets/img/logo.png" />
    //                 <h1 className="dDMandatePreTitleRow">Direct Debit Mandate</h1>
    //                 <p className="onboardingMainText ion-text-center">
    //                     Please confirm the bank account details on the next page are correct.
    //                 </p>

    //                 <div style={{ marginTop: "100%" }}>
    //                     <div className='bottomButtonContainer'>
    //                         <div className='bottomButtonRow'>
    //                             <IonButton onClick={goBack}>Go Back &#187;</IonButton>
    //                             <SubmitButton text="Next &#187;" onClick={start} />
    //                         </div></div>

    //                     {(config?.isDevelopment === true || config?.isStaging === true) && (
    //                         <IonButton onClick={onClickTestBankAccountButton}>Test Account info</IonButton>
    //                     )}
    //                 </div>

    //             </RowWithDynamicGutter>
    //         </IonGrid>
    //     </>}
    // </>
}
