import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { GetOnboardingAsync, Onboarding, StepNames, StepStatus } from '../../lib/data/OnboardingData';
import Spinner from '../controls/shared/Spinner';
import CompaniesHouseStep from './steps/CompaniesHouseSearch';
import { GridWithRowAndMiddleColumn } from '../controls/shared/Grid';
import './Onboarding.css';
import NominateApplicantStep from './steps/NominateApplicant';
import GettingStartedStep from './steps/GettingStarted';
import ManualStep from './steps/Manual';
import OnboardingMenu from './steps/OnboardingMenu';
import CompleteStep from './steps/Complete';

export default function OnboardingPage() {
    const [stepNumber, setStepNumber] = useState(0);
    const [onboarding, setOnboarding] = useState<Onboarding | null>(null);
    const lockApiCalls = useRef(false);

    async function getOnboardingData() {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            var onboarding = await GetOnboardingAsync();
            setOnboarding(onboarding);

            if (!onboarding) {
                setStepNumber(-99);
                return;
            }

            if (onboarding.steps?.get(StepNames.Complete)?.status === StepStatus.Complete) {
                setStepNumber(100);
                return;
            }

            if (onboarding?.requiresManualIntervention === true) {
                setStepNumber(-1);
                return;
            }

            if (onboarding.steps?.get(StepNames.GettingStarted)?.status !== StepStatus.Complete) {
                setStepNumber(1);
                return;
            }

            if (onboarding.steps?.get(StepNames.CompaniesHouse) && (onboarding.steps?.get(StepNames.CompaniesHouse)?.status !== StepStatus.Complete)) {
                setStepNumber(2);
                return
            }

            if (onboarding.steps?.get(StepNames.NominateApplicant) && (onboarding.steps?.get(StepNames.NominateApplicant)?.status !== StepStatus.Complete)) {
                setStepNumber(3);
                return;
            }

            setStepNumber(4);
        }
        finally {
            lockApiCalls.current = false
        };
    }    

    useEffect(() => {
        getOnboardingData();
    }, []);

    const errorForm = <>
    <IonPage>
        <IonContent>
            <GridWithRowAndMiddleColumn>
                <h1 className='questionnaireErrorTitleRow'>We've run into an issue</h1>
                <h5 className='questionnaireErrorTextRow'>Please email your contact details to customerservice@trustisttransfer.com to enquire about this account.</h5>
            </GridWithRowAndMiddleColumn>
        </IonContent>
    </IonPage>
    </>;

    function renderSwitch() {
        if (!onboarding) return;

        switch (stepNumber) {
            case -1: return <ManualStep onboarding={onboarding}></ManualStep>;
            case 1: return <GettingStartedStep onboarding={onboarding} onNext={getOnboardingData}></GettingStartedStep>;
            case 2: return <CompaniesHouseStep onboarding={onboarding} onNext={getOnboardingData}></CompaniesHouseStep>;
            case 3: return <NominateApplicantStep onboarding={onboarding} onNext={getOnboardingData}></NominateApplicantStep>;
            case 4: return <OnboardingMenu onboarding={onboarding} refresh={getOnboardingData}></OnboardingMenu>
            case 100: return <CompleteStep onboarding={onboarding} />;
            default: return <IonContent>{errorForm}</IonContent>;
        }
    };

    return <>{onboarding ? renderSwitch() : <Spinner />}</>;
}