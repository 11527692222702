import { IonButton, IonToggle, useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import { CardsStatus, Merchant, SetCardPaymentsEnabled } from "../../lib/data/Merchant";

type Props = {
    merchant: Merchant,
    refreshMerchant: Function,
};

export default function CardPayments(props: Props) {
    const merchant = props.merchant;
    const [enabled, setEnabled] = useState<boolean>(merchant.paymentMethods?.ryft?.enabled || false);
    const router = useIonRouter();

    useEffect(() => {
        async function toggleCardPayments() {
            await SetCardPaymentsEnabled(enabled);
            props.refreshMerchant();
        }

        if (merchant.paymentMethods?.ryft?.available &&
            merchant.paymentMethods?.ryft?.enabled !== enabled) {
            toggleCardPayments();
        }
    }, [enabled]);

    const notApplied = !merchant.paymentMethods?.ryft ||
        merchant.paymentMethods?.ryft?.status === CardsStatus.NotRequested;
    const setupInProgress =
        merchant.paymentMethods?.ryft?.status === CardsStatus.VerificationRequired ||
        merchant.paymentMethods?.ryft?.status === CardsStatus.VerificationSubmitted;
    const cardsOnboardingFailed =
        merchant.paymentMethods?.ryft?.status === CardsStatus.VerificationFailed;

    function checkSetupComplete() {
        router.push('/addCards')
    }

    return <>
            <h2 className="panelTitle">Card Payments</h2>
            {cardsOnboardingFailed && (
                <div>
                    <div className='ion-margin-top ion-margin-bottom' style={{ color: '#a0a0a0' }}>
                        There was an issue with your card setup, we will be in touch shortly.
                    </div>
                </div>
            )}
            {merchant.paymentMethods?.ryft?.available && (
                <div>
                    <div>
                        <div className="panelTitle">
                            <label>Enable Card Payments</label>
                            <IonToggle checked={enabled} onIonChange={e => setEnabled(e.detail.checked)}></IonToggle>
                        </div>
                    </div>
                    <div>
                        <p style={{ marginTop: '-3px', fontSize: '13px', marginLeft: '-1px' }}>&#40;Card Transaction Fees 0.99%&#41;</p>
                    </div>

                    <div className='ion-margin-top ion-margin-bottom' style={{ color: '#a0a0a0' }}>
                        Allow your customers to have the option to pay via card.
                    </div>
                </div>
            )}
            {setupInProgress && (
                <div>
                    <div className='ion-margin-top ion-margin-bottom' style={{ color: '#a0a0a0' }}>
                        We may need further input from you to complete the card payments setup process.  Use the button below to view the current status.
                    </div>
                    <IonButton onClick={() => checkSetupComplete()} className="setupButton"
                        color={"secondary"} >
                        Cards Onboarding &#187;
                    </IonButton>
                </div>
            )}
            {notApplied && (
                <div className='ion-margin-top ion-margin-bottom' style={{ color: '#a0a0a0' }}>
                    Use the button below to give your customers the option to pay via card.
                    <IonButton onClick={() => checkSetupComplete()} className="ion-margin-top setupButton"
                        color={"secondary"}>
                        Set up Card Payments &#187;
                    </IonButton>
                </div>
            )}
    </>
}