import { GetOnboardUrl, Onboarding, OnboardStatus } from '../../../lib/data/OnboardingData';
import PageSpinner from '../../controls/shared/PageSpinner';

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function IdentityCheckStep(props: Props) {
    const applicant = props.onboarding.businessDetails.beneficiaries.find(b => b.isApplicant === true);
    if (!applicant) {
        props.onNext();
        return <PageSpinner />;
    }

    const onboardStatus = applicant.onboardSession?.status;
    if (onboardStatus === OnboardStatus.Waiting ||
        onboardStatus === OnboardStatus.Complete) {
        props.onNext();
        return <PageSpinner />;
    }

    GetOnboardUrl().then(url => window.location.href = url);
    return <PageSpinner />; // might be rendered very briefly?
}