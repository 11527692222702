import axios from "axios";
import { GetAccessToken } from "./Auth";

const axiosInstance = axios.create({
    baseURL: '/api',
});

const defaultConfig = {
    responseType: 'json',
    validateStatus: (status: number) => (status >= 200 && status < 300) || status === 404 || status === 409 || status === 424,
};

export const jsonConfig = {
    responseType: 'json',
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
    async (originalResponse) => {
        const headers = originalResponse?.headers;
        if (!headers) return;

        const accessToken = await GetAccessToken();
        if (accessToken) {
            headers["Authorization"] = "Bearer " + accessToken;
        }

        if (!headers["Content-Type"]) {
            headers["Content-Type"] = "application/json";
        }

        return originalResponse;
    },
    (error) => {
        Promise.reject(error);
    }
);

export const ApiPost = (url: string, data: any = null, config: any = defaultConfig) => axiosInstance.post(url, data, config);

export const ApiDelete = (url: string, data: any = null, config: any = defaultConfig) => axiosInstance.delete(url, data);

export const ApiPut = (url: string, data: any, config?: any) => {
    if (config && config?.headers) {
        return axiosInstance.put(url, data,
            {
                headers: config.headers
            }
        );
    }

    return axiosInstance.put(url, data);
}

export const ApiGet = (url: string, config: any = defaultConfig) => axiosInstance.get(url, config);

export async function ApiFileDownload(url: string) : Promise<boolean> {
    await axiosInstance({
        url,
        method: 'GET',
        responseType: 'blob', // Important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/);
            if (fileNameMatch?.length === 2)
            {
                fileName = fileNameMatch[1];
            }
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });

    return true;
}
