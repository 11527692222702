import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { BankAccount, GetQuerystringValue, SetBankAccountAsync } from "../../lib";
import { RequestAccountInformationServicesUrlAsync, StoreAccountInformationTokenAsync } from "../../lib/data/Ais";
import { AccountContext } from "../controls/nav/AccountProvider";
import BankAccountSelector from "../controls/shared/BankAccountSelector";
import { ButtonRef, SubmitButton } from "../controls/shared/Buttons";
import { GlobalToastContext } from "../controls/shared/GlobalToastProvider";
import { RowWithDynamicGutter } from "../controls/shared/Grid";
import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";

export default function TransactionMonitoringPage() {
    const history = useHistory();
    const globalToastContext = useContext(GlobalToastContext);
    const buttonRef = useRef<ButtonRef>(null);
    const [requestId, setRequestId] = useState<string>();
    const isProcessingRequestId = useRef(false);
    const accountContext = useContext(AccountContext);
    const router = useIonRouter();

    async function startAisJourney() {
        try {
            var url = await RequestAccountInformationServicesUrlAsync(`/tabs/transactionmonitoring`, true);
            if (url && url > '') {
                window.location.href = url;
                return;
            }
        } catch (e) {
            console.log(e);
        }

        globalToastContext.error("There was a problem connecting to your bank. Please try again or contact us for support.");
    }

    function clearRequestId() {
        setRequestId(undefined);
        isProcessingRequestId.current = false;
    }

    async function bankAccountSelected(bankAccount: BankAccount) {
        await SetBankAccountAsync(bankAccount);
        await accountContext?.refresh();
        clearRequestId();
    }

    useEffect(() => {
        if (accountContext?.merchant?.paymentMethods?.tokenIo?.standingOrdersMonitored === true) router.push("/tabs/newstandingorder", "root");
    }, [accountContext?.merchant])

    useEffect(() => {
        let requestId = GetQuerystringValue("request-id");
        if (requestId) {
            if (isProcessingRequestId.current === true) return;
            isProcessingRequestId.current = true;

            StoreAccountInformationTokenAsync(requestId, true);
            setRequestId(requestId);
        }
    }, []);

    const initialScreen = <>
    <IonPage>
        <IonContent>
            <RowWithDynamicGutter>
                <IonImg className='smallLogo' src='./assets/img/logo.png' />
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <h1 style={{ textAlign: 'left', color: '#003466', fontSize: '20px', fontWeight: 'bold' }}>Transaction Monitoring</h1>
                <p style={{ textAlign: 'left', color: '#003466' }}>To enable monitoring of your transactions you will have to provide access to your account before proceeding.</p>


                <p style={{ fontSize: '15px', fontWeight: 'bold' }}> This only allows us to:</p>
                <ul style={{ fontSize: '15px', color: '#003466', }}>
                    <li>View your bank account details</li>
                    <li> View transactions</li>
                </ul>

                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>This does not allow us to:</p>
                <ul style={{ fontSize: '15px', color: '#003466', marginBottom: '30px' }}>
                    <li>Create transaction on your behalf</li>
                    <li>Change any details within your account</li>
                </ul>

                <SubmitButton
                    onClick={() => startAisJourney()}
                    text="Set Up Monitoring &#187;"
                    ref={buttonRef} />
                <p style={{ color: '#ff7100', textAlign: 'center', fontSize: '15px' }}>
                    To maintain the monitoring, permissions will be confirmed every 90 days. If not confirmed, monitoring will be disabled.
                </p>
            </RowWithDynamicGutter>
        </IonContent>
    </IonPage>
    </>;

    return <>
        {requestId ? <BankAccountSelector onSelected={bankAccountSelected} requestId={requestId} onCancel={clearRequestId} /> : initialScreen}
    </>;
}