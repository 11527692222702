import { IonButton, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonPage, IonRow } from "@ionic/react";
import { useRef, useState } from "react";
import { AboutBusinessQuestions, Onboarding, SaveAboutBusiness } from "../../../lib";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function AboutYourBusinessStep(props: Props) {
    const [aboutBusiness, setAboutBusiness] = useState<AboutBusinessQuestions>({
        companyName: props.onboarding.businessDetails.companyName,
        website: props.onboarding.businessDetails.website || '',
        phoneNumber: props?.onboarding?.applicant?.phoneNumber || '',
        applicantRole: props?.onboarding?.applicant?.role || '',
    } as AboutBusinessQuestions);
    const buttonRef = useRef<ButtonRef>(null);

    function isNullOrWhitespace(str: string | null | undefined): boolean {
        return (!str || str.length === 0 || /^\s*$/.test(str))
    }

    function validateRequiredFields(
        phoneNumber?: string,
        website?: string,
        companyName?: string,
        businessRole?: string) {
        if ((isNullOrWhitespace(phoneNumber)) ||
            isNullOrWhitespace(website) ||
            isNullOrWhitespace(companyName) ||
            isNullOrWhitespace(businessRole)) {
            buttonRef.current?.stopSpinning();
            return false;
        }
        else {
            return true;
        }
    }

    async function onClickComplete(e: any) {
        var valid = validateRequiredFields(aboutBusiness.phoneNumber, aboutBusiness.website, aboutBusiness.companyName, aboutBusiness.applicantRole);


        if (valid === false) {
            return;
        }
        else {
            await SaveAboutBusiness(aboutBusiness);
            props.onNext();
        }
    }

    function goBack() {
        props.onNext()
    }

    const form = <>
        <IonPage>
            <IonContent>
                <IonGrid>
                    <RowWithDynamicGutter>
                        <IonImg className='smallLogo' src='./assets/img/logo.png' />
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>

                        <h1 className='sectionTitle'>About Your Business</h1>

                        <p className="onboardingSubText">Please make sure all the information below is complete and correct</p>


                        <div style={{ color: '#003466' }}><h5 className="businessNameTitle">Full Name of Business*</h5></div>
                        <IonInput
                            value={aboutBusiness?.companyName}
                            className='questionInput'
                            type="text"
                            onIonChange={(event) => {
                                const newValue = event.detail.value || ''; // If the value is null or undefined, set it to an empty string
                                setAboutBusiness({ ...aboutBusiness, companyName: newValue });
                            }} ></IonInput>

                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <div>
                            <div style={{ color: '#003466' }}><h5 className="businessNameTitle">Business Website*</h5></div>
                        </div>
                        <IonInput
                            className='questionInput'
                            required
                            type="text"
                            value={aboutBusiness.website}
                            onIonChange={(event) => {
                                const newValue = event.detail.value || ''; // If the value is null or undefined, set it to an empty string
                                setAboutBusiness({ ...aboutBusiness, website: newValue });
                            }} ></IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <div>
                            <div style={{ color: '#003466' }}><h5 className="businessNameTitle">Your Role in The Business*</h5></div>
                        </div>
                        <IonInput
                            className='questionInput'
                            required
                            type="text"
                            value={aboutBusiness.applicantRole}
                            onIonChange={(event) => {
                                const newValue = event.detail.value || ''; // If the value is null or undefined, set it to an empty string
                                setAboutBusiness({ ...aboutBusiness, applicantRole: newValue });
                            }} >
                        </IonInput>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <div>
                            <div style={{ color: '#003466' }}><h5 className="businessNameTitle">Phone Number*</h5></div>
                        </div>
                        <IonRow>
                            <div style={{ marginRight: '5px' }}>+44</div>
                            <IonInput
                                autocomplete="tel-national"
                                className='questionInput'
                                required
                                type="tel"
                                value={aboutBusiness.phoneNumber}
                                onIonChange={(event) => {
                                    let newValue = event.detail.value || ''; // If the value is null or undefined, set it to an empty string

                                    // Remove all non-numeric characters
                                    newValue = newValue.replace(/\D/g, '');

                                    // Remove leading zero if present
                                    if (newValue.startsWith('0')) {
                                        newValue = newValue.substring(1);
                                    }
                                    setAboutBusiness({ ...aboutBusiness, phoneNumber: newValue });
                                }}></IonInput>
                        </IonRow>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <div>
                            <div style={{ marginBottom: '-10px', marginTop: '20px', fontSize: '15px', color: '#003466' }}>*Required fields</div>
                        </div>
                        <div className="bottomButtonContainer">
                            <div className="bottomButtonRow">
                                <IonButton onClick={goBack}>Go Back &#187;</IonButton>
                                <SubmitButton
                                    disabled={aboutBusiness.applicantRole === '' || aboutBusiness.phoneNumber === '' || aboutBusiness.companyName === '' || aboutBusiness.website === ''}
                                    ref={buttonRef}
                                    onClick={onClickComplete}
                                    text="Next »" />
                            </div>
                        </div>
                    </RowWithDynamicGutter>
                </IonGrid >
                <div className='paddingDiv'></div>
            </IonContent>
        </IonPage>
    </>;

    return form;
}