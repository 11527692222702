import { IonContent, IonGrid, IonImg, IonPage } from "@ionic/react";
import { useRef } from "react";
import { Onboarding } from "../../../lib";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import { OnboardLinkShare } from "../controls/OnboardLinkSharing";

export type OwnerVerificationProps = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function OwnerVerificationStep(props: OwnerVerificationProps) {
    const completeButtonRef = useRef<ButtonRef>(null);
    const people = props.onboarding.businessDetails.beneficiaries.filter(p => (p.isApplicant || false) === false);

    function onClickComplete() {
        props.onNext();
    }

    const inviteForm = <>
        <IonPage>
            <IonContent>
                <IonGrid>
                    <RowWithDynamicGutter>
                        <IonImg className='smallLogo' src='./assets/img/logo.png' />
                        <h1 className='sectionTitle'>Invite For Verification</h1>
                        <p className="onboardingSubText">
                            We need all people listed in the company documents who directly own or control more than 25% of your business to verify their identity
                        </p>
                    </RowWithDynamicGutter>

                    {people.map(person => <OnboardLinkShare
                        key={person.id}
                        person={{
                            id: person.id,
                            name: `${person.firstName} ${person.lastName}`,
                            address: person.address,
                            shareLink: person.shareLink,
                            onboardSession: person.onboardSession,
                        }} />)}

                    <RowWithDynamicGutter>
                        <SubmitButton
                            class="ion-margin-top"
                            ref={completeButtonRef}
                            onClick={onClickComplete}
                            text="Continue »" />
                    </RowWithDynamicGutter>
                </IonGrid>
            </IonContent>
        </IonPage>
    </>;

    return inviteForm;
}