import { CompleteOnboarding, Onboarding, Step, StepNames, StepStatus } from "../../../lib";
import { IonCol, IonContent, IonImg, IonPage, IonRow } from "@ionic/react";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import { SubmitButton } from "../../controls/shared/Buttons";
import { useEffect, useState } from "react";
import AboutYourBusinessStep from "./AboutYourBusiness";
import BankAccountStep from "./BankAccount";
import IdentityCheckStep from "./IdentityCheck";
import BigBusinessStep from "./BigBusiness";
import HelpModal from "../../controls/shared/HelpModal";
import { useLocation, useHistory } from "react-router";
import DirectDebitStep from "./DirectDebit";
import OwnerVerificationStep from "./OwnerVerification";
import AdditionalInformationStep from "./AdditionalInformation";
import Logout from "../../settings/Logout";

type Props = {
    onboarding: Onboarding,
    refresh: Function;
};

class MenuItem implements Step {
    displayName: string;
    status: StepStatus;

    constructor(displayName: string, status: StepStatus = StepStatus.Pending) {
        this.displayName = displayName;
        this.status = status;
    }

    isFinished() {
        return this.status >= StepStatus.Failed;
    }

    isStarted() {
        return this.status >= StepStatus.Started;
    }

    cloneWithStatus(newStatus: StepStatus) {
        return new MenuItem(this.displayName, newStatus);
    }
};

const MenuSteps = new Map<string, MenuItem>([
    [StepNames.AboutBusiness, new MenuItem("About Your Business")],
    [StepNames.DirectorCheck, new MenuItem("Owner Verification")],
    [StepNames.OnboardSession, new MenuItem("Identity Check")],
    [StepNames.BankAccount, new MenuItem("Bank Account Selection")],
    [StepNames.DirectDebitMandate, new MenuItem("Direct Debit Setup")],
    [StepNames.AdditionalInformation, new MenuItem("Additional Information")],
    [StepNames.BigBusiness, new MenuItem("Larger Business Form")],
]);

// iterates through all potential menu steps and returns the ones that are valid for this onboarding
const determineRelevantSteps = (onboarding: Onboarding): Map<string, MenuItem> => {
    var result: Map<string, MenuItem> = new Map<string, MenuItem>();

    MenuSteps.forEach((step, key) => {
        let onboardingStep = onboarding.steps.get(key);
        if (onboardingStep) {
            let newMenuItem = step.cloneWithStatus(onboardingStep.status);
            result.set(key, newMenuItem);
        }
    });

    return result;
};

const getStatusText = (step: MenuItem) => {
    if (step.status === StepStatus.Complete) return <span style={{ fontSize:"11px", color: "#58C800" }}>Complete</span>;
    if (step.status === StepStatus.Failed) return <span style={{fontSize:"11px", color:"#FF0404"}}>Failed</span>;
    if (step.isStarted()) return <span style={{ fontSize:"11px", color: "#FFA700" }}>See Progress</span>;
    return <span style={{fontSize:"11px", color: "#808080" }}>Click to Start</span>;
};

export default function OnboardingMenu(props: Props) {
    let steps = determineRelevantSteps(props.onboarding);
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const [selectedItem, setSelectedItem] = useState<string>();
    let anyIncomplete = Array.from(steps.values()).some(step => !step.isFinished());
    let stepArray: JSX.Element[] = [];

    // check for any querystring entries that might affect how we render
    useEffect(() => {
        // on return from ais go back to the bank account screen
        if (params.get('ais')) {
            params.delete('ais');

            history.replace({
                search: params.toString()
            });

            setSelectedItem(StepNames.BankAccount);
        }

        // on return from onboard refresh the data in a few seconds
        if (params.get('onboard')) {
            params.delete('onboard');

            history.replace({
                search: params.toString()
            });

            let pollForOnboardStatusChange = async (attempt: number = 1) => {
                if (attempt >= 30 || steps.get(StepNames.OnboardSession)?.isFinished()) return;
                props.refresh();
                setTimeout(() => { pollForOnboardStatusChange(attempt + 1) }, 500);
            };

            pollForOnboardStatusChange();
        }
    }, [location, history, params]);

    function onStepComplete() {
        setSelectedItem(undefined);
        props.refresh();
    }

    async function completeOnboarding() {
        await CompleteOnboarding();
        props.refresh();
    }

    steps.forEach((step, key) => {
        if (step.isFinished()) {
            stepArray.push(
                <IonRow className="onboardingMenuItem selectableItem" key={key}>
                    <IonCol className="onboardingMenuItemTitle" size="8" style={{ fontWeight: "bold", color: "#c3c3c3", }} >{step.displayName}</IonCol>
                    <IonCol className="menuStatus" size="4" style={{ fontWeight: "bold", }} >{getStatusText(step)}</IonCol>
                </IonRow>
            );
        } else {
            stepArray.push(
                <IonRow className="onboardingMenuItem selectableItem" key={key} onClick={() => setSelectedItem(key)}>
                    <IonCol className="onboardingMenuItemTitle" size="8" style={{ fontWeight: "bold", color: "#003466", }}>{step.displayName}</IonCol>
                    <IonCol className="menuStatus" size="4" style={{ fontWeight: "bold", }} >{getStatusText(step)}</IonCol>
                </IonRow>
            );
        }
    });

    const menu = <>
    <IonPage>
        <IonContent>
            <HelpModal modalMessage="Test" />
            <RowWithDynamicGutter>

                <IonImg className="smallLogo ion-margin-bottom" src='./assets/img/logo.png' />

                <h1 className='sectionTitle'>Account Setup</h1>
                <p className="onboardingSubText">
                    Please complete all the steps below to successfully create your account.
                </p>

                {stepArray}

                <SubmitButton
                    class="ion-margin-top"
                    disabled={anyIncomplete}
                    text="Complete »"
                    onClick={() => completeOnboarding()} />
                <Logout className="logoutTextButton" />
            </RowWithDynamicGutter>
        </IonContent>
    </IonPage>
    </>;

    switch (selectedItem) {
        case StepNames.AboutBusiness: return <AboutYourBusinessStep onboarding={props.onboarding} onNext={onStepComplete} />
        case StepNames.BankAccount: return <BankAccountStep onboarding={props.onboarding} onNext={onStepComplete} />;
        case StepNames.OnboardSession: return <IdentityCheckStep onboarding={props.onboarding} onNext={onStepComplete} />;
        case StepNames.BigBusiness: return <BigBusinessStep onboarding={props.onboarding} onNext={onStepComplete} />;
        case StepNames.DirectDebitMandate: return <DirectDebitStep onboarding={props.onboarding} onNext={onStepComplete} />;
        case StepNames.DirectorCheck: return <OwnerVerificationStep onboarding={props.onboarding} onNext={onStepComplete} />;
        case StepNames.AdditionalInformation: return <AdditionalInformationStep onboarding={props.onboarding} onNext={onStepComplete} />;
        default: return menu;
    };
};