import { IonButton, IonContent, IonIcon, IonPage, IonText, IonToolbar, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Merchant } from "../../lib";
import { AccountContext } from "../controls/nav/AccountProvider";
import { SubmitButton } from "../controls/shared/Buttons";
import { RowWithSmallGutters } from "../controls/shared/Grid";
import PageSpinner from "../controls/shared/PageSpinner";
import TransactionList from "../payments/TransactionList";

export default function SubMerchant() {
    var [merchant, setMerchant] = useState<Merchant | undefined | null>();
    const location = useLocation();
    const segments = location.pathname.split('/');
    const subMerchantName = segments.pop();
    const [showTransactions, setShowTransactions] = useState(false);
    var accountContext = useContext(AccountContext);
    const router = useIonRouter();

    useEffect(() => {
        setMerchant(accountContext?.merchant);
    }, [accountContext?.merchant]);

    let subMerchant = merchant?.submerchants.find(s => s.name === subMerchantName);

    const transactionView = <>
        <IonToolbar className='titleBar'>
            <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
                <IonButton onClick={() => router.goBack()} className="circularButton">
                    <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
                </IonButton>
            </div>
            
            <h2 className="titleBarText">{subMerchant?.name}</h2>
        </IonToolbar>
        <TransactionList subMerchantName={subMerchant?.name}></TransactionList>
    </>;

    const submerchantView = <>
        <IonToolbar className='titleBar'>
            <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
                <IonButton onClick={() => router.goBack()} className="circularButton">
                    <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
                </IonButton>
            </div>
            
            <h2 className="titleBarText">{subMerchant?.name}</h2>
        </IonToolbar>
        <IonContent>
            <RowWithSmallGutters colClassName="greyPanel">
                <h2 className="panelTitle">Transactions</h2>

                <SubmitButton
                    text="View &#187;"
                    onClick={() => setShowTransactions(true)} />
            </RowWithSmallGutters>

            <RowWithSmallGutters colClassName="greyPanel">
                <h2 className="panelTitle">Help</h2>

                <a onClick={() => router.push("/tabs/ContactSupport")}><u>Contact Support</u></a>
            </RowWithSmallGutters>
        </IonContent>
    </>;

    return <>{subMerchant
        ? <>
            <IonPage>
                {showTransactions ? transactionView : submerchantView}
            </IonPage>
        </>
        : <PageSpinner></PageSpinner>}
    </>;
}