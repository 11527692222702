import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { InfiniteScrollCustomEvent, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonToolbar, useIonRouter } from "@ionic/react";
import { ListStandingOrderPresets, StandingOrderFrequency, StandingOrderPreset } from "../../lib/data/StandingOrders";
import Spinner from "../controls/shared/Spinner";
import Routes from "../../Routes";

export default function StandingOrdersPresetsPage() {
    const history = useHistory();
    const location = useLocation();
    const continuation = useRef<string | undefined>(undefined);
    const [searchText, setSearchText] = useState<string | undefined>();
    const [searchFrequency, setSearchFrequency] = useState<StandingOrderFrequency | undefined>();
    const [searchResults, setSearchResults] = useState<StandingOrderPreset[] | undefined>();
    const lockApiCalls = useRef(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const router = useIonRouter();

    async function getDataAsync(ev?: InfiniteScrollCustomEvent) {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;
        setShowSpinner(true);

        try {
            const response = await ListStandingOrderPresets(continuation.current, searchText, searchFrequency);

            if ((response?.data?.length ?? 0) === 0) {
                if (response.continuation === null) setSearchResults([]);
                return;
            }

            (continuation?.current && continuation.current > '')
                ? setSearchResults([...searchResults!, ...response.data])
                : setSearchResults(response.data);

            continuation.current = response.continuation;
        }
        catch (e) {

        }
        finally {
            lockApiCalls.current = false;
            setShowSpinner(false);
            if (ev) ev.target.complete();
        }
    }

    useEffect(() => {
        if (!location.pathname.toLowerCase().startsWith(Routes.StandingOrderPresetList.toLowerCase())) return;
        continuation.current = undefined;
        getDataAsync();
    }, [searchText, searchFrequency, location.pathname]);

    function convertFrequencyToString(frequency: number) {
        switch (frequency) {
            case 1:
                return "Daily";
            case 2:
                return "Weekly";
            case 3:
                return "TwiceWeekly";
            case 4:
                return "Monthly";
            case 5:
                return "TwiceMonthly";
            case 6:
                return "Quarterly";
            case 7:
                return "SemiAnnually";
            case 8:
                return "Annually";
            default:
                return "Unknown Frequency";
        }
    }


    const searchResultList = <>
        <IonGrid fixed>
            <IonList>
                {searchResults?.map((item, index) => {
                    return (
                        <IonItem className='listItem' id={`payment_${item.id}`} key={item.id} onClick={() => history.push("/tabs/standingorderpreset", item)}>
                            <IonRow style={{ width: '100%' }}>
                                <IonCol size="5">
                                    <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><p style={{ color: '#003466', }}><b>{item.name}</b></p></div>
                                </IonCol>
                                <IonCol size="4">
                                    <p style={{ color: '#003466' }}>{item.payload.frequency !== undefined
                                        ? <span style={{ fontWeight: 500 }}>{convertFrequencyToString(item.payload.frequency)}</span>
                                        : "Unknown Frequency"
                                    }</p>
                                </IonCol>
                                <IonCol size="3">
                                    <p style={{ color: '#003466', textAlign: 'end', marginRight: '20px' }}>£{item.payload.amount}</p>
                                </IonCol>
                            </IonRow>
                        </IonItem>
                    );
                })}
                <IonInfiniteScroll threshold="200px" onIonInfinite={getDataAsync}>
                    <IonInfiniteScrollContent loadingSpinner="circular" loadingText="Loading...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonList>
        </IonGrid>
    </>;

    return <>
        <IonPage>
            <IonToolbar className='titleBar'>
                <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
                    <IonButton onClick={() => router.goBack()} className="circularButton">
                        <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
                    </IonButton>
                </div>
                <IonText class='titleBarText'>Payment Presets</IonText>
            </IonToolbar>
            <IonContent>
                <IonRow style={{
                    justifyContent: 'center',
                }}>
                    <IonCol sizeXs='1' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5'></IonCol>
                    <IonCol sizeXs='10' sizeSm='8' sizeMd='6' sizeLg='4' sizeXl='3'>
                        <IonButton color="primary"
                            onClick={() => router.push("/tabs/standingorderpreset")}>Create New &#187;
                        </IonButton>
                    </IonCol>
                    <IonCol sizeXs='1' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5'></IonCol>
                </IonRow>
                <IonGrid className="tabPageBottomMargin">
                    {(searchResults || showSpinner) ? searchResultList : <Spinner />}
                </IonGrid>
            </IonContent>
        </IonPage>
    </>;
}