import { IonContent, IonPage, IonText, IonToolbar } from '@ionic/react';
import './PaymentHistory.css';
import TransactionList from './payments/TransactionList';

export default function PaymentHistory() {
    return <>
        <IonPage>
            <IonContent>
                <IonToolbar className='titleBar'>
                    <IonText>Transactions</IonText>
                </IonToolbar>
                <TransactionList></TransactionList>
            </IonContent>
        </IonPage>
    </>;
}