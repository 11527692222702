import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useContext, useEffect, useState } from 'react';

import '@ionic/react/css/core.css';
import './App.css';
import './theme/variables.css';

import { EventType, IPublicClientApplication } from '@azure/msal-browser';
import { Route } from 'react-router';
import { AppConfig, GetConfig, GetMsalInstance } from './lib';
import AccountProvider, { AccountConsumer, AccountContext, AccountContextType } from './pages/controls/nav/AccountProvider';
import TabNav from './pages/controls/nav/TabNav';
import GlobalToastProvider from './pages/controls/shared/GlobalToastProvider';
import Loading from './pages/Loading';
import Onboarding from './pages/onboarding/Onboarding';
import Invite from './pages/submerchants/SubMerchantInvite';
import AddCards from './pages/cards/AddCards';
import ReauthoriseMonitoringPage from './pages/monitoring/ReauthoriseMonitoring';

setupIonicReact();

function App() {
    const accountContext = useContext(AccountContext);
    const [config, setConfig] = useState<AppConfig>();

    useEffect(() => {
        let callbackId: any;
        let msalInstance: IPublicClientApplication;

        async function addAccountCallback() {
            msalInstance = await GetMsalInstance();

            callbackId = msalInstance.addEventCallback(async (message: any) => {
                if (message.eventType === EventType.HANDLE_REDIRECT_END) {
                    if (accountContext) accountContext.refresh();
                }
            });
        }

        const result = addAccountCallback();

        return function () {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId);
            }
        }
    }, []);

    function renderContent(ctx: AccountContextType) {
        switch (ctx.account?.status) {

            case 'ACTIVE':
                return <TabNav />;
            case 'NEW':
            case 'ONBOARDING':
                return <Onboarding />;

            default: return <></>
        };
    }

    useEffect(() => { GetConfig().then(c => setConfig(c)); }, []);

    function hideStagingBanner() {
        if (config?.isStaging === true || config?.isDevelopment === true || config?.isSandbox === true) {
            return 'stagingBanner'
        }
        else {
            return 'ion-hide'
        }
    }

    function environmentBannerName() {
        if (config?.isSandbox === true) {
            return 'Sandbox'
        }
        else if (config?.isStaging === true) {
            return 'Staging'
        }
        else if (config?.isDevelopment === true) {
            return 'Development'
        }
        else {
            return ''
        }
    }

    function mainNav() {
        return <AccountConsumer>
            {ctx => ctx?.account
                ? renderContent(ctx)
                : <Loading />
            }
        </AccountConsumer>;
    }

    return (
        <AccountProvider>
            <IonApp>
                <GlobalToastProvider>
                    <IonReactRouter>
                        <IonRouterOutlet id="main">
                            <Route path="/tabs" component={mainNav} />
                            <Route path="/" component={mainNav} exact />
                            <Route path="/addCards" component={AddCards} exact />
                            <Route path="/addCards" component={AddCards} exact />
                            <Route path="/reauthoriseMonitoring" component={ReauthoriseMonitoringPage} exact />
                            <Route path="/Invite/:InviteCode" component={Invite} exact />
                        </IonRouterOutlet>
                    </IonReactRouter>
                </GlobalToastProvider>
                <div className={hideStagingBanner()}>
                    {environmentBannerName()}
                </div>
            </IonApp>
        </AccountProvider>
    );
};

export default App;
