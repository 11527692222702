import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { useContext, useRef } from "react";
import { Redirect, Route } from "react-router";
import TransactionMonitoringPage from "../../monitoring/TransactionMonitoring";
import PaymentHistory from "../../PaymentHistory";
import Settings from "../../Settings";
import contactSupport from "../../settings/ContactSupport";
import NewStandingOrderPage from "../../standingorders/NewStandingOrder";
import StandingOrderPresetPage from "../../standingorders/StandingOrderPreset";
import StandingOrderPresetList from "../../standingorders/StandingOrderPresetList";
import StandingOrdersPage from "../../standingorders/StandingOrdersList";
import NewSubMerchant from '../../submerchants/NewSubMerchant';
import SubMerchant from "../../submerchants/SubMerchant";
import SubMerchantList from "../../submerchants/SubMerchantList";
import cancelAccount from "../../subscription/CancelAccount";
import Home from "../../Home";
import { AccountContext } from './AccountProvider';
import DownloadDataControl from "../../settings/DownloadData";
import PaymentPresetsListPage from "../../paymentpresets/PaymentPresetsList";
import PaymentPresetPage from "../../paymentpresets/PaymentPreset";
import Routes from "../../../Routes";

export default function TabNav() {
    const accountContext = useContext(AccountContext);
    const tabsRef = useRef<any>(null);

    return <>
        <IonTabs ref={tabsRef}>
            <IonRouterOutlet>
                <Redirect path="/" exact to={Routes.Home}></Redirect>
                <Route path={Routes.Home} component={Home} exact />
                <Route path={Routes.PaymentHistory} component={PaymentHistory} exact />
                <Route path={Routes.Settings} component={Settings} exact />
                <Route path={Routes.CancelAccount} component={cancelAccount} exact />
                <Route path={Routes.ContactSupport} component={contactSupport} exact />
                <Route path={Routes.SubMerchantList} component={SubMerchantList} exact />
                <Route path={Routes.NewSubMerchant} component={NewSubMerchant} exact />
                <Route path={Routes.SubMerchant} component={SubMerchant} />
                <Route path={Routes.StandingOrdersPage} component={StandingOrdersPage} exact />
                <Route path={Routes.NewStandingOrderPage} component={NewStandingOrderPage} exact />
                <Route path={Routes.StandingOrderPresetList} component={StandingOrderPresetList} exact />
                <Route path={Routes.StandingOrderPresetPage} component={StandingOrderPresetPage} exact />
                <Route path={Routes.TransactionMonitoringPage} component={TransactionMonitoringPage} exact />
                <Route path={Routes.DownloadDataControl} component={DownloadDataControl} exact />
                <Route path={Routes.PaymentPresetsListPage} component={PaymentPresetsListPage} exact />
                <Route path={Routes.PaymentPresetPage} component={PaymentPresetPage} exact />
            </IonRouterOutlet>

            <IonTabBar slot="bottom">
                <IonTabButton tab="Take Payment" href={Routes.Home}>
                    <IonIcon src="assets\icon\credit-card.svg" />
                    <IonLabel></IonLabel>
                </IonTabButton>
                <IonTabButton tab="Payment History" href={Routes.PaymentHistory}>
                    <IonIcon src="assets\icon\bar-chart-2-3.svg" />
                    <IonLabel></IonLabel>
                </IonTabButton>
                {accountContext?.account?.merchantType === "merchant"
                    && <IonTabButton tab="Sub-Merchants" href={Routes.SubMerchantList}>
                        <IonIcon src="assets\icon\people-outline.svg" />
                        <IonLabel></IonLabel>
                    </IonTabButton>}
                <IonTabButton tab="Settings" href={Routes.Settings}>
                    <IonIcon src="assets\icon\settings-3.svg" />
                    <IonLabel></IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    </>;
};
