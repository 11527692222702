import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

import { InfiniteScrollCustomEvent, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonToolbar, useIonRouter } from "@ionic/react";
import { ListStandingOrders, StandingOrder, StandingOrderFrequency } from "../../lib/data/StandingOrders";
import Spinner from "../controls/shared/Spinner";
import StandingOrderModal from "./StandingOrderModal";
import Routes from "../../Routes";

export default function StandingOrdersPage() {
    const location = useLocation();
    const continuation = useRef<string | undefined>(undefined);
    const [searchText, setSearchText] = useState<string | undefined>();
    const [searchFrequency, setSearchFrequency] = useState<StandingOrderFrequency | undefined>();
    const [searchResults, setSearchResults] = useState<StandingOrder[]>();
    const [selectedItem, setSelectedItem] = useState<StandingOrder | undefined>();
    const lockApiCalls = useRef(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollToTop = () => {
        contentRef.current && contentRef.current.scrollToTop();
    };
    const router = useIonRouter();

    async function getDataAsync(ev?: InfiniteScrollCustomEvent) {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;
        setShowSpinner(true);

        try {
            const response = await ListStandingOrders(continuation.current, searchText, searchFrequency);

            if ((response?.data?.length ?? 0) === 0) {
                if (response.continuation === null) setSearchResults([]);
                return;
            }

            (continuation?.current && continuation.current > '')
                ? setSearchResults([...searchResults!, ...response.data])
                : setSearchResults(response.data);

            continuation.current = response.continuation;
        }
        catch (e) {

        }
        finally {
            lockApiCalls.current = false;
            setShowSpinner(false);
            if (ev) ev.target.complete();
        }
    }

    useEffect(() => {
        if (!location.pathname.toLowerCase().startsWith(Routes.StandingOrdersPage.toLowerCase())) return;
        continuation.current = undefined;
        getDataAsync();
    }, [searchText, searchFrequency, location.pathname]);

    const onClickCancelModel = () => {
        setSelectedItem(undefined);
        getDataAsync();
        scrollToTop();
    };

    function convertFrequencyToString(frequency: number) {
        switch (frequency) {
            case 1:
                return "Daily";
            case 2:
                return "Weekly";
            case 3:
                return "TwiceWeekly";
            case 4:
                return "Monthly";
            case 5:
                return "TwiceMonthly";
            case 6:
                return "Quarterly";
            case 7:
                return "SemiAnnually";
            case 8:
                return "Annually";
            default:
                return "Unknown Frequency";
        }
    }

    function convertStatusToString(status: number) {
        switch (status) {
            case 0:
                return "Unknown";  // dummy/empty/should not be used
            case 10:
                return "Pending"; // created but no further action has taken place
            case 20:
                return "Pending"; // payer has begun the process
            case 30:
                return "Pending"; // payer has returned from Token
            case 50:
                return "Failed"; // failed to create the standing order
            case 70:
                return "Abandoned"; // the process never completed (not yet used but should be)
            case 100:
                return "Active"; // the standing order is processing payments
            case 200:
                return "Completed";
            default:
                return "Status Unknown";
        }
    }

    function statusColorChanger(status: number) {

        switch (status) {
            case 0:
                return "#6B6B6B";  // dummy/empty/should not be used
            case 10:
                return "#FFA700"; // created but no further action has taken place
            case 20:
                return "#FFA700"; // payer has begun the process
            case 30:
                return "#FFA700"; // payer has returned from Token
            case 50:
                return "#FF0000"; // failed to create the standing order
            case 70:
                return "#FF0000"; // the process never completed (not yet used but should be)
            case 100:
                return "#58C800"; // the standing order is processing payments
            case 200:
                return "#58C800";
            default:
                return "#6B6B6B";
        }

    }

    function goBack() {
        router.goBack();
    }

    const searchResultList = <>
        <IonList>

            {searchResults?.map((item, index) => {
                return (
                    <IonItem className='listItem' id={`payment_${item.id}`} key={item.id} onClick={() => setSelectedItem(item)}>
                        <IonRow style={{ width: '100%' }}>
                            <IonCol size="8">
                                <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                    <p style={{ color: '#003466' }}>
                                        <b>{item.payload.payerName}</b><br />
                                        ID - {item.id}<br />
                                        {item.payload.frequency !== undefined
                                            ? <span style={{ fontWeight: 500 }}>{convertFrequencyToString(item.payload.frequency)}</span>
                                            : "Unknown Frequency"}
                                        <br />
                                        <span style={{ color: statusColorChanger(item.status) }}> {convertStatusToString(item.status)}</span>
                                    </p>
                                </div>
                            </IonCol>
                            <IonCol size="4">
                                <p style={{ color: '#003466', textAlign: 'end', marginTop: '45px', fontSize: '20px' }}>£{item.payload.amount?.toFixed(2)}</p>
                            </IonCol>
                        </IonRow>
                    </IonItem>
                );
            })}

            <IonInfiniteScroll threshold="200px" onIonInfinite={getDataAsync}>
                <IonInfiniteScrollContent loadingSpinner="circular" loadingText="Loading...">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>
        </IonList>
    </>;

    return <>
        <IonPage>
            <IonToolbar className='titleBar'>
                <IonText class='titleBarText'>Standing Orders</IonText>
                <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
                    <IonButton onClick={goBack} className="circularButton">
                        <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
                    </IonButton>
                </div>
            </IonToolbar>
            <IonContent>
                <IonGrid style={{ height: '85%' }}>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='0' sizeMd='1' sizeLg='1' sizeXl='2'> </IonCol>
                        <IonCol sizeXs='7' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='8'>
                            <IonInput
                                className="input"
                                onIonChange={val => setSearchText(val.detail.value || undefined)}
                                value={searchText}>
                            </IonInput>
                        </IonCol>
                        <IonCol sizeXs='3' sizeSm='3' sizeMd='3' sizeLg='1' sizeXl='1'>
                            <IonButton
                                style={{ width: '120px' }}
                                shape="round"
                                color="secondary"
                                size="small"
                                onClick={() => getDataAsync()}>
                                Search &#187;
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='0' sizeSm='0' sizeMd='1' sizeLg='1' sizeXl='2'></IonCol>
                        <IonCol sizeXs='10' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='10'>
                            <div style={{ paddingTop: '5px' }} className="searchFilterDiv">
                                <IonList>
                                    <IonItem lines="none">
                                        <IonSelect
                                            className='smallSelect'
                                            placeholder="Frequency"
                                            interface="alert"
                                            onIonChange={(e) => setSearchFrequency(e.detail.value)}
                                            //onIonCancel={onClickCancelStatusSelect}
                                            value={searchFrequency}>
                                            <IonSelectOption value="">All</IonSelectOption>
                                            {/* <IonSelectOption value={StandingOrderFrequency.Daily}>Daily</IonSelectOption> */}
                                            <IonSelectOption value={StandingOrderFrequency.Weekly}>Weekly</IonSelectOption>
                                            <IonSelectOption value={StandingOrderFrequency.Monthly}>Monthly</IonSelectOption>
                                            <IonSelectOption value={StandingOrderFrequency.Annually}>Annually</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonList>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonGrid style={{ paddingBottom: '60px' }} fixed>
                        {(searchResults || showSpinner) ? searchResultList : <Spinner />}
                    </IonGrid>
                </IonGrid>

            </IonContent>
            <IonRow style={{
                justifyContent: 'center',
                position: 'sticky',
                bottom: 10,
                zIndex: 1, // Set a higher z-index value to ensure it appears above the toolbar
            }}>
                <IonCol sizeXs='1' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5'></IonCol>
                <IonCol sizeXs='10' sizeSm='8' sizeMd='6' sizeLg='4' sizeXl='3'>
                    <div className='bottomButtonContainer'>
                        <div className='bottomButtonRow'>
                            <IonButton color="primary" routerLink="/tabs/standingorderpresets">Presets &#187;</IonButton>
                            <IonButton color="secondary" routerLink="/tabs/newstandingorder">Create New &#187;</IonButton>
                        </div>
                    </div>
                </IonCol>
                <IonCol sizeXs='1' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5'></IonCol>
            </IonRow>
        </IonPage>
        
        {selectedItem && <StandingOrderModal item={selectedItem} onClosed={onClickCancelModel} />}
    </>;
}