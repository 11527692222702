import { IonCol, IonRow } from "@ionic/react"
import { useContext } from "react";
import { AccountContext } from "../controls/nav/AccountProvider";
import { SubmitButton } from "../controls/shared/Buttons";

export default function ResetAccount() {
    const account = useContext(AccountContext);

    const resetAccount = () => {
        if (!account) return;
        account.reset().then(() => account.refresh());
    };

    return <>
        <SubmitButton text='Reset Account' onClick={resetAccount} colour="primary" />
    </>;
}