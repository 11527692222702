import { IonButton, IonContent, IonGrid, IonImg, IonInput, IonPage } from "@ionic/react";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import FileUploadControl from "../../controls/shared/FileUpload";
import { BigBusinessPost, ChangeOnboardingMedia, DownloadOnboardingMedia, Onboarding, OnboardMedia, OnboardMediaType, UploadOnboardingMedia } from "../../../lib";
import { CheckBoxRow } from "../../controls/shared/Checkbox";
import { createRef, useRef, useState } from "react";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import BusinessLicenseFileControl, { BusinessLicenseFileControlRef } from "../controls/BusinessLicenseFileControl";
import useLocalStorageState from "../../controls/hooks/useLocalStorageState";

export type BigBusinessProps = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function BigBusinessStep(props: BigBusinessProps) {
    const propsCertificates = props.onboarding.media.filter(m => m.type === OnboardMediaType.Certificate);
    const [certificatesRequired, setCertificatesRequired] = useState<boolean>(propsCertificates.length > 0);
    const [sanctionsText, setSanctionsText] = useLocalStorageState<string | undefined>("sanctionsText", undefined);
    const [validationError, setValidationError] = useState(false);
    const submitButtonRef = useRef<ButtonRef>(null);
    const [certificates, setCertificates] = useState(propsCertificates);
    let certificateElements: JSX.Element[] = [];
    const [certificateRefs, setCertificateRefs] = useState<React.RefObject<BusinessLicenseFileControlRef>[]>([]);

    const areAllCertificatesReady = () => certificateRefs.every(ref => ref.current && (ref.current.isDescriptionSet() || ref.current.isFileSet()));

    certificates.forEach((c, index) => {
        certificateElements.push(
            <BusinessLicenseFileControl
                key={index}  // Use index as the key, has obvious limitations but the UI doesn't demand any more
                ref={certificateRefs[index]}
                media={c}
                onFileChange={(media: OnboardMedia, file: File) => UploadMedia(media, file)}
                onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)}
                onDescriptionChange={(media: OnboardMedia) => UploadMedia(media)}
            />);
    });

    function FindMediaTypeInModel(mediaType: number) {
        return props.onboarding.media?.find(m => m.type === mediaType);
    }

    function GetFileName(mediaType: number): string {
        var filename = FindMediaTypeInModel(mediaType)?.fileName;
        return (filename && filename > '') ? filename : '';
    }

    async function UploadSingleInstanceMedia(file: File, mediaType: number): Promise<string> {
        var id = FindMediaTypeInModel(mediaType)?.id;

        return (id && id > '')
            ? await ChangeOnboardingMedia(id, mediaType, file)
                .then((result) => result.fileName!)
            : await UploadOnboardingMedia(mediaType, file)
                .then((result) => result.fileName!);
    }

    async function UploadMedia(media: OnboardMedia, file?: File): Promise<OnboardMedia> {
        if (!media.type) return {};

        return (media.id && media.id > '')
            ? await ChangeOnboardingMedia(media.id, media.type, file, media.description)
                .then((result) => result)
            : await UploadOnboardingMedia(media.type, file, media.description)
                .then((result) => result);
    }

    async function OnClickComplete() {
        if (certificatesRequired && areAllCertificatesReady() === false) {
            setValidationError(true);
            submitButtonRef.current?.stopSpinning();
            return;
        }

        var result = await BigBusinessPost(sanctionsText);

        if (result === true) {
            props.onNext();
        } else {
            setValidationError(true);
            submitButtonRef.current?.stopSpinning();
        }
    }

    function goBack() {
        props.onNext()
    }


    const form = <>
        <IonPage>
            <IonContent>
                <IonGrid>

                    <RowWithDynamicGutter>
                        <IonImg className="smallLogo" src='./assets/img/logo.png' />

                        <h1 className='sectionTitle'>Larger Business Form</h1>
                        <p className='onboardingSubText ion-margin-bottom'>
                            As a larger business we require more information from you to complete setup
                        </p>
                    </RowWithDynamicGutter>

                    <FileUploadControl
                        title="Certificate of Incorporation *"
                        fileName={GetFileName(OnboardMediaType.CertificateOfIncorporation)}
                        onFileChange={(file: File) => UploadSingleInstanceMedia(file, OnboardMediaType.CertificateOfIncorporation)}
                        onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)}></FileUploadControl>

                    <RowWithDynamicGutter><div className="borderDiv" /></RowWithDynamicGutter>

                    <FileUploadControl
                        title="Memorandum & Articles of Association *"
                        fileName={GetFileName(OnboardMediaType.ArticlesOfAssociation)}
                        onFileChange={(file: File) => UploadSingleInstanceMedia(file, OnboardMediaType.ArticlesOfAssociation)}
                        onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)}></FileUploadControl>

                    <RowWithDynamicGutter><div className="borderDiv" /></RowWithDynamicGutter>

                    <FileUploadControl
                        title="Group Structure Chart *"
                        fileName={GetFileName(OnboardMediaType.GroupStructureChart)}
                        onFileChange={(file: File) => UploadSingleInstanceMedia(file, OnboardMediaType.GroupStructureChart)}
                        onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)}></FileUploadControl>

                    <RowWithDynamicGutter><div className="borderDiv" /></RowWithDynamicGutter>

                    <FileUploadControl
                        title="Anti-Money Laundering Policy *"
                        fileName={GetFileName(OnboardMediaType.AmlPolicy)}
                        onFileChange={(file: File) => UploadSingleInstanceMedia(file, OnboardMediaType.AmlPolicy)}
                        onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)}></FileUploadControl>

                    <RowWithDynamicGutter><div className="borderDiv" /></RowWithDynamicGutter>

                    <FileUploadControl
                        title="Anti-Bribery and Corruption Policy *"
                        fileName={GetFileName(OnboardMediaType.AntiBriberyPolicy)}
                        onFileChange={(file: File) => UploadSingleInstanceMedia(file, OnboardMediaType.AntiBriberyPolicy)}
                        onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)}></FileUploadControl>

                    <RowWithDynamicGutter><div className="borderDiv" /></RowWithDynamicGutter>

                    <FileUploadControl
                        title="Data Protection Policy *"
                        fileName={GetFileName(OnboardMediaType.DataProtectionPolicy)}
                        onFileChange={(file: File) => UploadSingleInstanceMedia(file, OnboardMediaType.DataProtectionPolicy)}
                        onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)}></FileUploadControl>

                    <RowWithDynamicGutter><div className="borderDiv" /></RowWithDynamicGutter>

                    <FileUploadControl
                        title="Security Policy *"
                        fileName={GetFileName(OnboardMediaType.SecurityPolicy)}
                        onFileChange={(file: File) => UploadSingleInstanceMedia(file, OnboardMediaType.SecurityPolicy)}
                        onFileSelect={(fileName: string) => DownloadOnboardingMedia(fileName)}></FileUploadControl>

                    <RowWithDynamicGutter><div className="borderDiv" /></RowWithDynamicGutter>

                    <RowWithDynamicGutter>
                        <div className="onboardingMainText">
                            <b>Does your business activity require any licence/certification?</b>
                        </div>
                        <p className="onboardingSubText">
                            For example:
                        </p>
                        <ul className="onboardingSubText" style={{ color: "#003466" }}>
                            <li>Gaming licence/certification</li>
                            <li>Financial service providers/PSP/EMI</li>
                        </ul>
                    </RowWithDynamicGutter>

                    <div style={{ marginTop: '-15px' }}>
                        <CheckBoxRow
                            checked={certificatesRequired}
                            label="Please tick the box if yes"
                            onChange={(checked) => setCertificatesRequired(checked)}></CheckBoxRow>
                    </div>

                    {certificatesRequired && <>
                        <RowWithDynamicGutter>
                            <div style={{ marginTop: '30px', fontSize: '17px' }} className="onboardingMainText">
                                <b>Does your organisation hold such required licences/certification?</b>
                            </div>
                            <p className="onboardingSubText">
                                If the answer is ‘Yes’ please list them below.
                            </p>
                            <p className="onboardingSubText">
                                If the answer is ‘No’, please provide details as to why.
                            </p>

                            {certificateElements}
                            <div className="borderDiv" />
                            <div>
                                <IonButton style={{ textDecoration: 'underline', width: 'auto', marginLeft: '-10px', marginTop: '-5px', marginBottom: '10px' }}
                                    fill="clear"
                                    onClick={() => {
                                        // Add a new entry
                                        const newCertificate = { type: OnboardMediaType.Certificate };
                                        setCertificates(prev => [...prev, newCertificate]);

                                        // Add a new ref for this entry
                                        setCertificateRefs(prev => [...prev, createRef<BusinessLicenseFileControlRef>()]);
                                    }}>
                                    <b>Add New Entry Line</b>
                                </IonButton>
                            </div>

                        </RowWithDynamicGutter>

                    </>}

                    <RowWithDynamicGutter>
                        <div className="borderDiv ion-padding" />
                        <div className="onboardingMainText ion-margin-top">
                            <b>Have you ever received any legal sanctions or been fined by any authorities?</b>
                        </div>
                        <p className="onboardingSubText">
                            If the answer is ‘Yes’ please provide details and what has been done to rectify the situation.
                        </p>
                        <IonInput
                            className='input'
                            type="text"
                            value={sanctionsText}
                            onIonChange={(val) => setSanctionsText(val.detail.value!)} ></IonInput>
                    </RowWithDynamicGutter>

                    <RowWithDynamicGutter>
                        {validationError &&
                            <p className="errorText">
                                Please make sure that all fields are correctly filled out and all attachments are attached.
                            </p>}
                        <div>
                            <div style={{ marginBottom: '-10px', marginTop: '20px', fontSize: '15px', color: '#003466' }}>*Required fields</div>
                        </div>
                        <div className='bottomButtonContainer'>
                            <div className='bottomButtonRow'>
                                <IonButton onClick={goBack}>Go Back &#187;</IonButton>
                                <SubmitButton
                                    ref={submitButtonRef}
                                    onClick={OnClickComplete}
                                    text="Complete »" />

                            </div>
                        </div>
                    </RowWithDynamicGutter>
                </IonGrid>
            </IonContent>
        </IonPage>
    </>;

    return form;
}