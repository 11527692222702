import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, IonText } from "@ionic/react";
import { Onboarding, StepStatus } from "../../../lib";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import './Complete.css';

export type CompleteStepProps = {
    onboarding: Onboarding
};

export default function CompleteStep(props: CompleteStepProps) {
    const anyFailed = Array.from(props.onboarding.steps.values()).some(step => step.status === StepStatus.Failed);

    return <>
        <IonPage>
            <IonContent>
                <IonGrid>
                    <RowWithDynamicGutter>
                        <IonImg className='smallLogo' src='./assets/img/logo.png' />
                    </RowWithDynamicGutter>

                    {anyFailed ? (
                        <>
                            <RowWithDynamicGutter>
                                <h1 className='completiontitleRow'>
                                    Oops, there’s an issue!
                                </h1>
                            </RowWithDynamicGutter >
                            <RowWithDynamicGutter>
                                <IonText>
                                    <h5 className="onboardingText">
                                        We're sorry, but it seems there's been a hiccup with your identification verification. Don't worry; our dedicated team is here to help you every step of the way.
                                    </h5>
                                </IonText>
                            </RowWithDynamicGutter>
                            <RowWithDynamicGutter>
                                <IonText>
                                    <h5 className="onboardingText">
                                        Please keep an eye on your email or phone for a message from our team.
                                    </h5>
                                </IonText>
                            </RowWithDynamicGutter>
                        </>
                    ) : (
                        <>
                            <RowWithDynamicGutter>
                                <IonImg className="tickImage" src="./assets/img/successTick.png" />
                            </RowWithDynamicGutter>
                            <RowWithDynamicGutter>
                                <h1 className='completiontitleRow'>Congratulations, You've Finished all the Steps!</h1>
                            </RowWithDynamicGutter>
                            <RowWithDynamicGutter>

                                <h5 className="onboardingText">
                                    We'll now prepare your account, and you'll receive an email confirmation when it's ready.
                                </h5>

                            </RowWithDynamicGutter>
                        </>
                    )}
                    <RowWithDynamicGutter>
                        <h5 className="onboardingText">
                            <b>Our opening times are &#40;GMT&#41;:</b>
                        </h5>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                        <IonRow className="onboardingMainText">
                            <IonCol style={{ textAlign: "left" }}>
                                Monday - Thursday
                            </IonCol>
                            <IonCol style={{ textAlign: "right" }}>
                                9:00 - 18:00
                            </IonCol>
                        </IonRow>
                        <IonRow className="onboardingMainText" >
                            <IonCol style={{ textAlign: "left" }}>
                                Friday
                            </IonCol>
                            <IonCol style={{ textAlign: "right" }}>
                                9:00 - 14:00
                            </IonCol>
                        </IonRow>
                        <IonRow className="onboardingMainText">
                            <IonCol style={{ textAlign: "left" }}>
                                Saturday - Sunday
                            </IonCol>
                            <IonCol style={{ textAlign: "right" }}>
                                Closed
                            </IonCol>
                        </IonRow>
                    </RowWithDynamicGutter>
                </IonGrid>
            </IonContent>
        </IonPage>
    </>;
}