import { ApiGet, ApiPost, Director } from '..';
import { CardsStatus, Merchant } from './Merchant';

export type CardApplication = {
    status: CardsStatus,
    directorsAdded: number,
    directorsRemaining: number,
    directorsAvailable: Director[],
};

export const GetCardsApplication = async () => {
    var response = await ApiGet("GetCardsApplication");
    return response?.data;
}

export const StartCardsApplication = async () => {
    var response = await ApiPost("StartCardsApplication");
    return response.status === 200;
}