import { ApiGet, ApiPost, jsonConfig } from '..';

export type Account = {
    status: string,
    emailAddress: string,
    merchantType: string, // merchant, submerchant

    fixedAmount: number, // only for use in Nigel's subaccounts, nothing else
};

export type LogoFile = {
    formFile: File,
    fileName: string
};

const LoadAccountFromApi = async () => {
    var apiAccount = await ApiGet("GetAccount");
    return apiAccount?.data;
}

export const LoadAccount = async () => {
    var apiAccount = await LoadAccountFromApi() as Account;

    if (apiAccount) {
        return apiAccount;
    }

    // todo: could not get account details from storage or api, what do?
    return null;
}

export const ResetAccount = async () => {
    await ApiPost("PostAccountReset", {});
}

export async function AcceptSubMerchantInvitation(inviteCode: string) {
    try {
        await ApiPost("AcceptSubMerchantInvitation", { inviteCode }, jsonConfig);
        return true;
    }
    catch {
        return false;
    }
}