import { createContext, useEffect, useRef, useState } from "react";
import { Account, GetMerchantAsync, LoadAccount, Merchant, ResetAccount } from "../../../lib";

export interface AccountContextType {
    account: Account | undefined;
    merchant: Merchant | undefined | null;
    refresh: Function;
    reset: Function;
};

export const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const AccountConsumer = AccountContext.Consumer;

interface Props {
    children: React.ReactNode
}

export default function AccountProvider({ children }: Props) {
    const [merchant, setMerchant] = useState<Merchant | null>();
    const [account, setAccount] = useState<Account>();
    const lockApiCalls = useRef(false);

    function getAccount() {
        if (lockApiCalls.current === false) {
            lockApiCalls.current = true;

            GetMerchantAsync().then(m => setMerchant(m));

            LoadAccount()
                .then(account => setAccount(account!))
                .finally(() => lockApiCalls.current = false);
        }
    };

    useEffect(getAccount, []);

    return <AccountContext.Provider value={{
        account,
        merchant,
        refresh: () => getAccount(),
        reset: () => ResetAccount().then(() => getAccount()),
    }}>{children}</AccountContext.Provider>
}