import { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonCol, IonSpinner, IonText } from '@ionic/react';
import './LogoUpload.css';
import { Merchant, PostLogoAsync, GetLogoAsync } from '../../lib';
import { AccountContext } from '../controls/nav/AccountProvider';
import { GlobalToastContext } from '../controls/shared/GlobalToastProvider';

type Props = {
    merchant: Merchant,
    refreshMerchant: () => Promise<void>,
};

export default function LogoUpload(props: Props) {
    const merchant = props.merchant;
    const globalToastContext = useContext(GlobalToastContext);
    const [isUploadMode, setIsUploadMode] = useState(false);
    const [file, setFile] = useState();
    const fileInput = useRef(null);
    const accountContext = useContext(AccountContext);
    const [logo, setLogo] = useState<string>();
    const lockApiCalls = useRef(false);

    const onFileChange = (e: any) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile.size > 5000000) {
            globalToastContext.error("File size cannot be larger than 5MB");
        }
        else {
            setFile(e.target.files[0]);
            setIsUploadMode(true);
        }
        e.target.value = "";
    };

    const onFileUpload = (e: any) => {
        e.preventDefault();

        var bodyFormData = new FormData();
        bodyFormData.append("formFile", file as any);
        setLogo(undefined);
        PostLogoAsync(bodyFormData)
            .then(() => {
                accountContext?.refresh();

                globalToastContext.success("Logo successfully uploaded.");
            })
            .finally(() => {
                setIsUploadMode(false);
                accountContext?.refresh();
            }).catch(_ => {
                globalToastContext.error("An error occured");
            });
    };

    const tryGetLogo = async () => {
        if (lockApiCalls.current === true ||
            merchant.logoType === null ||
            typeof merchant.logoType === 'undefined') {
            return;
        }

        lockApiCalls.current = true;

        var response = await GetLogoAsync(merchant.logoType);
        if (response != null) {
            setLogo(response);
            lockApiCalls.current = false;
        }
    };

    const uploadText = () => {
        if (isUploadMode === true) {
            return "Your file is ready, please press the upload button"
        }
    }

    useEffect(() => {
        if (merchant.logoType! > '') {
            tryGetLogo();
        }
    }, [accountContext?.account]);

    const accountLogo = logo! > ''
        ? merchant.logoType === "Base64Logo"
            ? <img className='logoImage' src={logo} alt="Logo" />
            : <div dangerouslySetInnerHTML={{ __html: logo! }} />
        : isUploadMode ? <></> : <></>;

    return <>
        <div className="panelTitle">
            <h2>Your Logo </h2>
            <input
                ref={fileInput}
                hidden
                type='file'
                accept='image/png, image/svg'
                onChange={onFileChange}
                onClick={() => {
                }}
            />
            <IonButton
                color="secondary"
                size="small"
                shape="round"
                className="settingsButtons"
                hidden={isUploadMode}
                onClick={() => {
                    // @ts-ignore
                    fileInput?.current?.click();
                }}>Attach &#187;</IonButton>
            <IonButton
                color="primary"
                size="small"
                shape="round"
                className="settingsButtons"
                hidden={!isUploadMode}
                onClick={() => {
                    setFile(undefined);
                    setIsUploadMode(false);
                }}>Cancel &#187;</IonButton>
        </div>

        <div className='ion-margin-top ion-margin-bottom' style={{ color: '#a0a0a0' }}>
            Attach a high quality logo to be used on your receipts. SVG and PNG accepted, 5mb max file size.
        </div>

        {accountLogo && <div className='logoCol'>{accountLogo}</div>}

        {isUploadMode && <div className="ion-text-center">
            <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='5' sizeXl='5'></IonCol>
            <IonCol sizeXs='8' sizeSm='8' sizeMd='8' sizeLg='2' sizeXl='2'><IonText className='uploadMessage'><div>{uploadText()}</div></IonText></IonCol>
            <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='5' sizeXl='5'></IonCol>

            <IonSpinner className='uploadSpinner' hidden={!isUploadMode}></IonSpinner>
            <br></br>
            <IonButton
                shape="round"
                color="secondary"
                size="small"
                onClick={onFileUpload}
                className="settingsButtons">Upload &#187;
            </IonButton>
        </div>}
    </>
}