import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonInput, IonItem, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import { useRef, useState } from "react";
import { Onboarding, OrgTypes } from "../../../lib";
import { CompaniesHouseSearch, CompaniesHouseSelect, Company } from "../../../lib/data/CompaniesHouse";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import "./CompaniesHouseSearch.css";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import ItemListControl from "../../controls/shared/ItemListControl";

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function CompaniesHouseStep(props: Props) {
    const [compHouseSearchData, setCompHouseSearchData] = useState('');
    const [compHouseResultData, setCompHouseResultData] = useState<Company[]>([]);
    const [selectedCompanyNumber, setSelectedCompanyNumber] = useState<string>();
    const [errorMessage, setErrorMessage] = useState('');
    const searchButtonRef = useRef<ButtonRef>(null);

    async function handleCompHouseSearch() {
        const response = await CompaniesHouseSearch(compHouseSearchData);

        if (compHouseSearchData === '') {
            setErrorMessage('Please Enter a Business Name');
            searchButtonRef.current?.stopSpinning(true);
        }
        else if (response.length < 1) {
            setErrorMessage('No results, please check any spellings');
            searchButtonRef.current?.stopSpinning(true);
        }
        else {
            setErrorMessage('');
            setCompHouseResultData(response);
        }
    };

    async function onClickNotRegistered(e: any) {
        setCompHouseResultData([]);
        props.onNext();
    };

    async function handleSelectedCompany() {
        await CompaniesHouseSelect(selectedCompanyNumber!);
        setCompHouseResultData([]);
        props.onNext();
    }

    var companySelect = <>
    <IonPage>
        <IonContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className="smallLogo" src='./assets/img/logo.png' />
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                <div className="sectionTitle">
                    <b>Pick Your Business</b>
                </div>
                    <div className="listExtension">
                        <ItemListControl
                            items={compHouseResultData.map((company: Company) => ({ key: company.company_number, value: company.company_name }))}
                            onItemSelected={companyNumber => setSelectedCompanyNumber(companyNumber)}
                        /></div>
                    <div className='bottomButtonContainer'>
                        <div className='bottomButtonRow'>
                    <SubmitButton
                        class="submit-button"
                        disabled={!selectedCompanyNumber}
                        onClick={handleSelectedCompany}
                        text="Next »"
                    />
                    </div>
                    </div>
                    <IonButton
                        className="notRegisteredButton"
                        fill="clear"
                        onClick={onClickNotRegistered}
                    >
                        I Can't see my Business »
                    </IonButton>
                    </RowWithDynamicGutter>
            </IonGrid >
        </IonContent>
    </IonPage>
    </>;

    const companyList = <>
    <IonPage>
        <IonContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className="smallLogo" src='./assets/img/logo.png' />
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                    <h1 className="sectionTitle"><b>Business Registration Details</b></h1>
                    <div style={{ color: '#003466', marginBottom:'30px' }}>
                        <p className="onboardingSubText">
                        Please provide us with information about your business, this will help us verify your business faster
                        </p>
                    </div>
                    {/* <IonText color={"primary"}><h6 id='CompHouseInputLabel'>Business Name</h6></IonText> */}
                    <IonInput
                        placeholder="Business Name"
                        onIonChange={(e) => setCompHouseSearchData(e.target.value as string)}
                        value={compHouseSearchData}
                        className='questionInput'
                        type="text" />
                    <div className="errorMessageDiv">
                        <div className="error-message" hidden={errorMessage === ''}>{errorMessage}</div>
                    </div>
                    </RowWithDynamicGutter>
                    <RowWithDynamicGutter>
                    <div className='bottomButtonContainer'>
                        <div className='bottomButtonRow'>
                            <SubmitButton
                                ref={searchButtonRef}
                                onClick={() => handleCompHouseSearch()}
                                text="Search »" />
                        </div>
                    </div>
                    </RowWithDynamicGutter>             
                {/* {props.onboarding.businessDetails.orgType === OrgTypes.SoleTrader &&
                    <IonRow>
                        <IonCol sizeXs='1' sizeSm='1' sizeMd='3' sizeLg='3' sizeXl='3' />
                        <IonCol sizeXs='10' sizeSm='10' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <IonButton fill="clear" class="notRegisteredButton"
                                >
                                I&#39;m Not on Companies House »
                            </IonButton>
                        </IonCol>
                        <IonCol sizeXs='1' sizeSm='1' sizeMd='3' sizeLg='3' sizeXl='3' />
                    </IonRow>
                } */}
            </IonGrid >
        </IonContent>
    </IonPage>
    </>;
    return compHouseResultData.length > 0 ? companySelect : companyList;
}