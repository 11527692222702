import { IonContent, IonGrid, IonImg, IonPage, IonText } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { AcceptSubMerchantInvitation } from "../../lib";
import { AccountContext } from "../controls/nav/AccountProvider";
import { SubmitButton } from "../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../controls/shared/Grid";
import PageSpinner from "../controls/shared/PageSpinner";

export default function SubMerchantInvite() {
    const history = useHistory();
    const location = useLocation();
    const accountContext = useContext(AccountContext);
    const [ready, setReady] = useState(false);
    const [invitationComplete, setInvitationComplete] = useState(false);
    const lock = useRef(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    useEffect(() => {
        if (!ready || lock.current === true) return;
        lock.current = true;
        convertInvitation();
    }, [ready])

    // send the user back to root when the account is refreshed
    // i.e. the "done" button has been clicked
    useEffect(() => {
        if (!accountContext?.account) return;

        if (accountContext.account.merchantType === "submerchant") {
            history.replace('/');
        }

        setReady(true);
    }, [accountContext?.account]);

    if (!ready) return <PageSpinner />; // no point going on until the account has been created

    const segments = location.pathname.split('/');
    const inviteCode = segments.pop();

    async function convertInvitation() {
        if (!inviteCode) {
            setErrorMessage("Could not find an invitation code in this request, please contact customersupport@trustisttransfer.com if this continues.")
        } else {
            try {
                var result = await AcceptSubMerchantInvitation(inviteCode);

                if (result !== true) {
                    setErrorMessage("We failed to process this invitation, perhaps you are already a TrustistTransfer merchant? Please contact customersupport@trustisttransfer.com if this continues.")
                }
            }
            catch {
                setErrorMessage("We failed to process this invitation, please contact customersupport@trustisttransfer.com if this continues.");
            }
        }

        setInvitationComplete(true);
        lock.current = false;
    }

    const fail = <>
        <IonGrid>
            <RowWithDynamicGutter>
                <IonImg className='smallLogo' src='./assets/img/logo.png' />
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <IonImg src="./assets/img/failedCross.png" className="failOrSuccessImage" />
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <h1 className="mainHeader">Something Went Wrong!</h1>
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <IonText>
                    <p className="center">
                        {errorMessage}
                    </p>
                </IonText>
            </RowWithDynamicGutter>
        </IonGrid>
    </>;

    const complete = <>
        <IonGrid>
            <RowWithDynamicGutter>
                <IonImg className='smallLogo' src='./assets/img/logo.png' />
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <IonImg src="./assets/img/successTick.png" className="failOrSuccessImage" />
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <h1 className="mainHeader">All Done!</h1>
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <IonText>
                    <p className="center">
                        You can now start taking payments!
                    </p>
                </IonText>
            </RowWithDynamicGutter>
            <RowWithDynamicGutter>
                <SubmitButton text="Start Taking Payments &#187;" onClick={() => accountContext?.refresh()}></SubmitButton>
            </RowWithDynamicGutter>
        </IonGrid>
    </>;

    return <>{invitationComplete
        ? <IonPage><IonContent>{errorMessage ? fail : complete}</IonContent></IonPage>
        : <PageSpinner></PageSpinner>}
    </>;
}