import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import { useContext } from "react";
import { useHistory } from "react-router";
import { ReauthoriseMonitoring } from "../../lib/data/Ais";
import { AccountContext } from "../controls/nav/AccountProvider";
import { SubmitButton } from "../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../controls/shared/Grid";

export default function ReauthoriseMonitoringPage() {
    const history = useHistory();
    const accountContext = useContext(AccountContext);
    const router = useIonRouter();

    async function sendReauthorisationRequest() {
        await ReauthoriseMonitoring();
        await accountContext?.refresh();
        router.goBack();
    }

    return <>
        <IonPage>
            <IonContent style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <RowWithDynamicGutter>
                    <IonImg className="smallLogo" src='./assets/img/logo.png' />
                </RowWithDynamicGutter>

                <RowWithDynamicGutter>
                    <h1 className="sectionTitle">Transaction Monitoring</h1>

                    <p>
                        To enable monitoring of your transactions you will have to
                        provide access to your account before proceeding.
                    </p>

                    <h2 className="sectionSubTitle">This only allows us to:</h2>

                    <ul className="normalText">
                        <li>View your bank account details</li>
                        <li>View transactions</li>
                    </ul>

                    <h2 className="sectionSubTitle">This does not allow us to:</h2>

                    <ul className="normalText">
                        <li>Create transactions on your behalf</li>
                        <li>Change any details within your account</li>
                    </ul>

                    <SubmitButton onClick={sendReauthorisationRequest}
                        text="Set Up Monitoring &raquo;" />
                </RowWithDynamicGutter>
            </IonContent>
        </IonPage>
    </>;
}